import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import Modal from 'react-modal';
import './header.css';
import logo from '../../assets/photos/logoNew3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons'; // Correct import for faHouse
// import Signup from '../../components/signUp/signUp.js'; // Commented for future use
// import Login from '../../components/login/login.js'; // Commented for future use
// import { useAuth } from '../../contexts/AuthContext'; // Commented for future use

Modal.setAppElement('#root');

export default function Navigation() {
    // const { currentUser, logout: firebaseLogout } = useAuth(); // Commented for future use
    const [flowUser, setFlowUser] = useState({ loggedIn: false, addr: null });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // const [modalContent, setModalContent] = useState('signin-choice'); // Commented for future use

    useEffect(() => {
        const unsubscribe = fcl.currentUser().subscribe((user) => {
            setFlowUser({
                loggedIn: user.loggedIn,
                addr: user.addr,
            });
        });

        return () => {
            unsubscribe();
        };
    }, []);

    // useEffect(() => {
    //     if (currentUser) {
    //         setModalIsOpen(false);
    //     }
    // }, [currentUser]); // Commented for future use

    const openModal = () => {
        // setModalContent('signin-choice'); // Commented for future use
        setModalIsOpen(true);
    };

    const closeModal = () => setModalIsOpen(false);

    const handleFlowSignIn = () => {
        closeModal();
        fcl.logIn();
    };

    // const handleEmailSignIn = () => {
    //     setModalContent('login'); // Start with the login form for email sign-in
    // }; // Commented for future use

    const handleLogout = () => {
        // if (currentUser) {
        //     firebaseLogout();
        // } else 
        if (flowUser.loggedIn) {
            fcl.unauthenticate();
        }
    };

    // const switchToSignup = () => {
    //     setModalContent('signup');
    // }; // Commented for future use

    // const switchToLogin = () => {
    //     setModalContent('login');
    // }; // Commented for future use

    const isUserLoggedIn = /* currentUser || */ flowUser.loggedIn; // Only check Flow login for now
    const userAddressOrEmail = /* currentUser ? currentUser.email : */ flowUser.addr; // Display Flow address only

    return (
        <>
            <header className="header">
                <div className="header-top">
                    <Link to="/" className="social-media-link" aria-label="Homepage">
                        <FontAwesomeIcon icon={faHouse} className="icon" />
                    </Link>
                    <a href="https://x.com/vertico_defi" className="social-media-link" aria-label="Follow us on X"
                        target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} className="icon" /> {/* Updated icon reference */}
                    </a>
                    <a href="https://www.instagram.com/titpalace.xyz/" className="social-media-link" aria-label="Follow us on Instagram"
                        target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="icon" />
                    </a>
                </div>
                <nav className="navigation">
                    <Link to="/" className="nav-link">Bazaar</Link> 
                    <Link to="/parlor" className="nav-link">Parlor</Link>
                </nav>
                <div className="sign-in">
                    {isUserLoggedIn ? (
                        <>
                            <p className="address-text">{userAddressOrEmail}</p>
                            <button className="button" onClick={handleLogout}>Sign Out</button>
                        </>
                    ) : (
                        <button className="button" onClick={openModal}>Sign In</button>
                    )}
                </div>
            </header>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Sign In Modal"
                className="modal"
                overlayClassName="overlay"
            >
                <h2 style={{ textAlign: "center", marginBottom: "20px" }}>SIGN IN</h2>
                <div className="button-container">
                    <button onClick={handleFlowSignIn} className="option-button">$FLOW</button>
                    {/* <button onClick={handleEmailSignIn} className="option-button">Sign in with Email</button> */}
                    <button onClick={closeModal} className="option-button">Close</button>
                </div>
            </Modal>
        </>
    );
}
