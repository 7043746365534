import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import SearchAndFilter from './components/searchAndFilter';
import * as fcl from '@blocto/fcl';
import { ToastContainer, toast } from 'react-toastify';

import './homepage.css';
import { ThreeDots } from 'react-loader-spinner';
import { images } from '../../assets/images';
import  pageImages from '../../assets/pageImages';
import courtierImages from '../../assets/courtierImages';
import { GlobalContext } from "../../components/globalContext/globalState";

import { getAllListingDetails } from '../../cadence/scripts/getAllListings';
import { getPageDetails } from '../../cadence/scripts/getPageDetails';
import { getCourtierDetailsX1 } from '../../cadence/scripts/getCourtierDetailsX1';
import { purchasePageListing } from '../../cadence/transactions/purchasePageListing';


function HomePage({fetchFlowBalance}) {
    const { setSelectedItem } = useContext(GlobalContext);
    const [user, setUser] = useState({ loggedIn: null });
    const [userAddress, setUserAddress] = useState(null);
    const [selectedType, setSelectedType] = useState('pages');

    const [listings, setListings] = useState([]);

    const [isProcessing, setIsProcessing] = useState(false);
    const [processingCardID, setProcessingCardID] = useState(null);
    const [allListings, setAllListings] = useState([]);
    const [pageDetails, setPageDetails] = useState([]);
    const [courtierDetails, setCourtierDetails] = useState([]);
    const [isEnriched, setIsEnriched] = useState(false);
    const [visibleRows, setVisibleRows] = useState(3);  // Start with three visible rows
    const [initialListings, setInitialListings] = useState([]);
    const [isInitialLoaded, setIsInitialLoaded] = useState(false);
    const [rawPageData, setRawPageData] = useState([]);
    const [rawCourtierData, setRawCourtierData] = useState([]);
    const [filteredPageDetails, setFilteredPageDetails] = useState([]);
    const [filters, setFilters] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [pageListings, setPageListings] = useState([]);
    const [courtierListings, setCourtierListings] = useState([]);







    const imageCache = useMemo(() => new Map(), []);
    const storyRowRef = useRef(null);
    const imageRowRef = useRef(null);



    useEffect(() => {
        fcl.currentUser.subscribe(currentUser => {
            setUser(currentUser);
            setUserAddress(currentUser.addr);
        });
    }, []);





    // Fetch all listings
    const fetchAllListings = async () => {
        try {
            const listingRes = await fcl.query({
                cadence: getAllListingDetails,
                args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)],
            });
            console.log("Lesting Results: ", listingRes);

                    // Separate listings based on NFT type
            const pageListings = listingRes.filter(listing =>
                listing.details.nftTypes.some(type => type.typeID.includes("PagesX.NFT"))
            );

            const courtierListings = listingRes.filter(listing =>
                listing.details.nftTypes.some(type => type.typeID.includes("CourtiersX.NFT"))
            );

            setPageListings(pageListings); // Store only page listings
            setCourtierListings(courtierListings); // Store only courtier listings

            return listingRes;
        } catch (error) {
            console.error("Failed to fetch all listings:", error);
            return [];
        }
    };


    
    const fetchPageDetails = async () => {
        try {

            const pageDetails = await fcl.query({
                cadence: getPageDetails,
                args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)],
            });
            return pageDetails || [];  // Ensure it always returns an array
        } catch (error) {
            console.error("Failed to fetch page details:", error);
            return [];  // Return empty array on error
        }
    };

    const fetchCourtierDetails = async () => {
        try {
            const courtierDetails = await fcl.query({
                cadence: getCourtierDetailsX1,
                args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)],
            });
            return courtierDetails || [];
        } catch(error) {
            console.error("Faled to retrieve courtier details:", error);
            return [];
        }
    }
    
    useEffect(() => {
        const loadData = async () => {
            const listings = await fetchAllListings();
            const initListings = listings.slice(0, 15);
            const pages = await fetchPageDetails();
            const courtiers = await fetchCourtierDetails();
            setAllListings(listings);
            setInitialListings(initListings);
            setRawPageData(pages);
            setRawCourtierData(courtiers);   
            setIsInitialLoaded(true);  
    
        };
    
        loadData();
    }, []);  // Make sure this effect runs only once on component mount

        // Function to refresh the listings after purchase
    const refreshListings = async () => {
        setIsInitialLoaded(false);
        //  await fetchAndCacheNFTData();  // Fetch fresh listings from the backend
        setIsEnriched(false);
        const listings = await fetchAllListings();
        const initListings = listings.slice(0, 15);
        const pages = await fetchPageDetails();
        const courtiers = await fetchCourtierDetails();
        setAllListings(listings);
        setInitialListings(initListings);
        setRawPageData(pages);
        setRawCourtierData(courtiers);   
        setIsInitialLoaded(true);  
        };
      

    useEffect(() => {
        if (initialListings.length > 0 && rawPageData.length > 0) {
            console.log("Initial Listings: ", initialListings);
            console.log("Raw NFT Data:", rawPageData);
            const enrichedPageDetails = enrichPageDetails(pageListings, rawPageData);
            setPageDetails(enrichedPageDetails);
        }
    
        if (initialListings.length > 0 && rawCourtierData.length > 0) {
            const enrichedCourtierDetails = enrichPageDetails(courtierListings, rawCourtierData);
            setCourtierDetails(enrichedCourtierDetails);
        }
        console.log("You are here");
    }, [initialListings, rawPageData, rawCourtierData]);
    

    useEffect(() => {
        if (isInitialLoaded) {
            // Process the rest of the listings if the initial ones are loaded
            const remainingListings = allListings.slice(15); // Assuming initial 15 are already processed
            if (remainingListings.length > 0) {
                const enrichedRemainingPages = enrichPageDetails(remainingListings, rawPageData);
                setPageDetails(prevDetails => [...prevDetails, ...enrichedRemainingPages]);
            }
        }
    }, [isInitialLoaded, allListings, rawPageData]);

    function enrichPageDetails(listings, nfts) {
        console.log("LOOK HERE: ", nfts)
        // Ensuring string-based comparison by converting NFT IDs to strings in the map
        const nftMap = new Map(nfts.map(nft => [nft.nftId.toString(), nft]));
    
        const enriched = listings.map(listing => {
            // Access the first element of the first array of nftIDs (assuming each listing contains at least one NFT ID)
            const nftId = listing.details.nftIDs[0][0]; 
            const nftDetails = nftMap.get(nftId);

            //console.log("Listing ID: ", listing.listingID, "Sale Price: ", listing.details.salePrice);
    
            if (nftDetails) {
                return {
                    ...nftDetails,
                    listingData: {   // Ensure this structure matches the expected structure in handleBuy
                        listingID: listing.listingID,
                        price: listing.details.salePrice,
                    },
                    hp: calculateHP(nftDetails.traits || [])
                };
            }
            return null; // or some default object if no matching NFT details were found
        }).filter(detail => detail !== null);
    
        console.log("Enriched Page Details:", enriched);
        return enriched;
    }

    useEffect(() => {
        if (selectedType === 'pages') {
            setFilteredResults(pageDetails);
        } else if (selectedType === 'courtiers') {
            setFilteredResults(courtierDetails);
        }
    }, [selectedType, pageDetails, courtierDetails]);
    

    // Determine if filters are active
    const filterActive = Object.values(filters).some(set => set.size > 0);

    const handleFilterChange = (searchTerm, filters = {}) => {
        const filteredData = pageDetails.filter(page => {
          const matchesSearch = page.name.toLowerCase().includes(searchTerm.toLowerCase());
          const matchesFilters = Object.keys(filters).every(category =>
            filters[category].size === 0 || page.traits.some(trait =>
              trait.category === category && filters[category].has(trait.name)
            )
          );
          return matchesSearch && matchesFilters;
        });
        setFilteredPageDetails(filteredData);
      };
      
    
    const pickRandomTraits = (traits) => {
        if (!traits || traits.length === 0) {
            return [];
        } else if (traits.length <= 2) {
            return traits;
        } else {
            let shuffled = traits.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, 2);
        }
    };

    const stableRandomTraits = useMemo(() => {
        const data = selectedType === 'pages' ? pageDetails : courtierDetails;
        return data.map(item => ({
            ...item,
            traits: pickRandomTraits(item.traits)
        }));
    }, [pageDetails, courtierDetails, selectedType]); 
    

    const calculateHP = (traits) => {
        return traits.reduce((total, trait) => total + (parseFloat(trait.value) || 0), 0);
    };

    


    const handleBuy = async (page) => {
        try {
            setIsProcessing(true);
            setProcessingCardID(page.nftId);  // Set processing ID to current page ID
    
            const txid = await fcl.mutate({
                cadence: purchasePageListing,
                args: (arg, t) => [
                    arg('0xfdfe39186c5e3b90', t.Address),  // Replace with correct storefront address
                    arg(page.listingData.listingID, t.UInt64),
                    arg(page.listingData.price, t.UFix64),
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
    
            await fcl.tx(txid).onceSealed();
            await fetchFlowBalance(userAddress);
            refreshListings();
            setIsProcessing(false);
            setProcessingCardID(null);  // Clear processing ID after purchase
        } catch (error) {
            console.error("Error during purchase:", error);
            setIsProcessing(false);
            setProcessingCardID(null);  // Clear processing ID on error
        }
    };
    


    

    useEffect(() => {
        const cachedNFTData = localStorage.getItem('cachedNFTData');
        if (cachedNFTData && JSON.parse(cachedNFTData).length > 0) {
            //console.log("Using cached NFT data:", JSON.parse(cachedNFTData));
            setListings(JSON.parse(cachedNFTData));
        } else {
            // Fetch and display listings if cache is empty
      //      fetchAndCacheNFTData();
            fetchAllListings();
        }
    }, []);

    
    const renderImage = (thumbnail, type) => {
        if (!thumbnail || thumbnail === "N/A") {
            return "";  // Return a default image or empty if no valid thumbnail
        }
    
        // Pick the correct image collection
        const imgPath = type === 'pages' ? pageImages[thumbnail] : courtierImages[thumbnail];
    
        if (!imgPath) {
            console.error(`No image found for thumbnail: ${thumbnail}`);
            return "";  // Return a default image if no match found
        }
    
        return imgPath;
    };
    

        const renderTrait = (trait, index) => (
            <div key={index} className="trait">
                <div className="trait-row-1">
                    <div className="trait-category-name">
                        {trait.name}
                    </div>
                    <div className="trait-value">
                        {trait.value}
                    </div>
                </div>
                <div className="trait-row-2">
                    <div className="trait-description">
                        <small>{trait.description}</small>
                    </div>
                </div>
            </div>
        );
    
        const renderPagePortraitTile = (page, index) => (
            <div className="portrait-tile-home" key={index}>
                <div className="name-hp-container">
                    <h3>{page.name}</h3>
                    <div className="hp-container">
                        <span className="hp-value">{page.hp}</span>
                        <span className="hp-label">HP</span>
                    </div>
                </div>
                <div className="image-banner-2">
                    <img src={renderImage(page.thumbnail, selectedType)} alt={page.name} />
                    <div className="description-banner">{page.description}</div>
                </div>
                <div className="portrait-details">
                    {page.traits && page.traits.length > 0
                        ? page.traits.map((trait, index) => renderTrait(trait, index))
                        : <span>No traits available</span>}
                </div>
                <div className="purchase-section">
                    <div className="price-column">
                        {`${parseFloat(page.listingData?.price).toFixed(2)} $FLOW`}
                    </div>
                    {processingCardID === page.nftId ? (
                        <div className="loading-spinner-buy">
                            <ThreeDots height="40" width="80" color="#4B0082" ariaLabel="loading-indicator" />
                        </div>
                    ) : (
                        <button className="see-more-button" onClick={() => handleBuy(page)}>BUY</button>
                    )}
                </div>
            </div>
        );
        
    const handleSeeMore = () => {
        setVisibleRows(prevRows => prevRows + 3); // Add three more rows every time button is clicked
    };
    
    // Rearrange pageDetails based on selection
    const handleSelectItem = (selectedItem, type) => {
        if (type === 'pages') {
            setPageDetails([
                ...pageDetails.filter((page) => page.name.toLowerCase() === selectedItem.name.toLowerCase()),
                ...pageDetails.filter((page) => page.name.toLowerCase() !== selectedItem.name.toLowerCase()),
            ]);
        } else {
            setCourtierDetails([
                ...courtierDetails.filter((courtier) => courtier.name.toLowerCase() === selectedItem.name.toLowerCase()),
                ...courtierDetails.filter((courtier) => courtier.name.toLowerCase() !== selectedItem.name.toLowerCase()),
            ]);
        }
    
    
        // setPageDetails(reorderedDetails);
        setFilteredResults([]);
        setSearchTerm(selectedItem.name); // Autofill the search bar
    };
      
    
    return (
        <div className="homepage">

            <h1>Bazaar</h1>
            <SearchAndFilter
                onFilter={handleFilterChange}
                onSelectItem={handleSelectItem}
                pageDetails={pageDetails}
                courtierDetails={courtierDetails}
                selectedType={selectedType}
            />



            <div style={{ display: 'flex' }}>
                {filterActive && (
                <div className="filter-panel" style={{ width: '20%' }}>
                    {/* Filter panel content here */}
                </div>
                )}
            </div>
            <div className="type-selector">
                <button 
                    onClick={() => setSelectedType('pages')} 
                    className={`type-button ${selectedType === 'pages' ? 'active' : ''}`}
                >
                    Pages
                </button>
                <button 
                    onClick={() => setSelectedType('courtiers')} 
                    className={`type-button ${selectedType === 'courtiers' ? 'active' : ''}`}
                >
                    Courtiers
                </button>
            </div>
            <div className="image-row">
                {!isInitialLoaded ? (
                    <div className="loading-spinner">
                        <ThreeDots height="80" width="80" color="#007d7e" ariaLabel="loading-indicator" />
                    </div>
                ) : (
                    stableRandomTraits
                        .slice(0, visibleRows * 5)
                        .map((item, index) => renderPagePortraitTile(item, index))
                )}
            </div>

            {stableRandomTraits.length > visibleRows * 5 && isInitialLoaded && (
                <div className="load-more-container">
                    <button onClick={handleSeeMore} className="see-more-button-real">SEE MORE</button>
                </div>
            )}
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} />
        </div>
    );
}

export default HomePage;
