// import { init } from '@onflow/fcl-wc';
import * as fcl from '@onflow/fcl';

// export const initFclWc = async () => {
//   try {
//     const { FclWcServicePlugin, client } = await init({
//       projectId: "8c9205597881feb4cabff72159c1a6de",
//       includeBaseWC: true,
//       services: [], // This might need to be populated based on your needs or omitted if handled by the library
//     });

//     console.log('FclWcServicePlugin:', FclWcServicePlugin);
//     fcl.pluginRegistry.add(FclWcServicePlugin);
//   } catch (error) {
//     console.error('Error initializing WalletConnect with FCL:', error);
//   }
// };

fcl.config({
    "discovery.wallet": "https://fcl-discovery.onflow.org/authn",
    "accessNode.api": "https://rest-mainnet.onflow.org",
    "walletconnect.projectId": "8c9205597881feb4cabff72159c1a6de"
  });
