// Import all images
import CountessEleanordeVere170 from "./loadCourtiers/CountessEleanordeVere170.png";
import DameCharlotteSinclair170 from './loadCourtiers/DameCharlotteSinclair170.png';
import MarchionessLydiaPembroke190 from './loadCourtiers/MarchionessLydiaPembroke190.png';
import LadyVivienneSterling190 from './loadCourtiers/LadyVivienneSterling190.png';
import BaronessSophiaLancaster170 from './loadCourtiers/BaronessSophiaLancaster170.png';
import LadyAmeliaWentworth from './loadCourtiers/LadyAmeliaWentworth.png';
import CountessVictoriaAshwood230 from './loadCourtiers/CountessVictoriaAshwood230.png';
import DameAbigailHargrove230 from './loadCourtiers/DameAbigailHargrove230.png';
import MarchionessFionaCarrington190 from './loadCourtiers/MarchionessFionaCarrington190.png';
import LadyGenevieveMontfort from './loadCourtiers/LadyGenevieveMontfort.png';
import BaronessCeciliaBeaumont215 from './loadCourtiers/BaronessCeciliaBeaumont215.png';
import LadyRosalindClifford170 from './loadCourtiers/LadyRosalindClifford170.png';
import CountessMatildaCavendish170 from './loadCourtiers/CountessMatildaCavendish170.png';
import LadyPenelopeThornwood215 from './loadCourtiers/LadyPenelopeThornwood215.png';
import MarchionessHelenaRavensdale215 from './loadCourtiers/MarchionessHelenaRavensdale215.png';
import DameJosephineWycliffe170 from './loadCourtiers/DameJosephineWycliffe170.png';
import BaronessArabellaForsythe from './loadCourtiers/BaronessArabellaForsythe.png';
import LadyConstanceBlackwood215 from './loadCourtiers/LadyConstanceBlackwood215.png';
import CountessBeatriceHarrowgate190 from './loadCourtiers/CountessBeatriceHarrowgate190.png';
import MarchionessOliviaFairchild170 from './loadCourtiers/MarchionessOliviaFairchild170.png';
import LadyEleanorWhitmore215 from './loadCourtiers/LadyEleanorWhitmore215.png';
import DameVictoriaChesterfield215 from './loadCourtiers/DameVictoriaChesterfield215.png';
import BaronessLydiaHensley190 from './loadCourtiers/BaronessLydiaHensley190.png';
import LadyIsadoraXingu from './loadCourtiers/LadyIsadoraXingu.png';
import LadyEvelynRavenscroft from './loadCourtiers/LadyEvelynRavenscroft.png';
import BaronessPoppyFairweather from './loadCourtiers/BaronessPoppyFairweather.png';
import DameFlorenceHawthorne from './loadCourtiers/DameFlorenceHawthorne.png';


const courtierImages = {
    CountessEleanordeVere170, DameCharlotteSinclair170, MarchionessLydiaPembroke190, LadyVivienneSterling190, BaronessSophiaLancaster170,
    LadyAmeliaWentworth, CountessVictoriaAshwood230, DameAbigailHargrove230, MarchionessFionaCarrington190, LadyGenevieveMontfort,
    BaronessCeciliaBeaumont215, LadyRosalindClifford170, CountessMatildaCavendish170, LadyPenelopeThornwood215, MarchionessHelenaRavensdale215,
    DameJosephineWycliffe170, BaronessArabellaForsythe, LadyConstanceBlackwood215, CountessBeatriceHarrowgate190, MarchionessOliviaFairchild170,
    LadyEleanorWhitmore215, DameVictoriaChesterfield215, BaronessLydiaHensley190, LadyIsadoraXingu, LadyEvelynRavenscroft,
    BaronessPoppyFairweather, DameFlorenceHawthorne
};

export default courtierImages;

