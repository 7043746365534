import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useContext, useEffect, useState } from 'react'; // Added useEffect, useState
import { UserContext } from '../../contexts/userContext'; // Import the user context
import { useAuth } from '../../contexts/AuthContext';
import * as fcl from '@onflow/fcl'; // Import Flow FCL
import { getTitBalance } from '../../cadence/scripts/getTitBalance'; // Import Cadence script
import { checkFlowBalance } from '../../cadence/scripts/checkFlowBalance';

export default function Footer({flowBalance}) {
    const { flowAddress } = useContext(UserContext); // Access Flow address from UserContext
    const { currentUser } = useAuth(); // Access email from Firebase Auth
    const [titBalance, setTitBalance] = useState(0); // State to store the tit balance
    const [user, setUser] = useState({ loggedIn: null });
    const [userAddress, setUserAddress] = useState(null);
    // const [flowBalance, setFlowBalance] = useState(0);

    useEffect(() => {
        fcl.currentUser.subscribe(currentUser => {
            setUser(currentUser);
            setUserAddress(currentUser.addr);
        });
    }, []);


    // Function to fetch Tit Balance
    const fetchTitBalance = async (flowAddress) => {
        try {
            const titBalance = await fcl.query({
                cadence: getTitBalance,
                args: (arg, t) => [arg(flowAddress, t.Address)]
            });
            setTitBalance(titBalance); // Store the fetched Tit balance in state
        } catch (error) {
            console.error('Error fetching Tit Balance:', error);
        }
    };
    // const fetchFlowBalance = async (userAddress) => {
    //     try {
    //         const flowBalance = await fcl.query({
    //             cadence: checkFlowBalance,
    //             args: (arg, t) => [arg(userAddress, t.Address)]
    //         });
    //         // Ensure that flowBalance is a number and set it
    //         // setFlowBalance(Number(flowBalance));
    //     } catch (error) {
    //         console.error('Error fetching the Flow Balance:', error);
    //     }
    // };
    
    // Fetch the Tit balance when the flowAddress is available
    useEffect(() => {
        if (userAddress) {
            fetchTitBalance(userAddress);
            // fetchFlowBalance(userAddress);
        }
    }, [userAddress]);



    return (
        <footer>
            <div className="footer">
                <div className="text-left">
                    <p>Tit Palace &copy; 2025</p>
                </div>
                <div className="logo-foot">
                    {/* Icons should be in the same row */}
                    <div className="social-icons">
                        <a href="https://x.com/vertico_defi" className="social-media-link" aria-label="Follow us on X"
                            target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} className="icon" />
                        </a>
                        <a href="https://www.instagram.com/titpalace/" className="social-media-link" aria-label="Follow us on Instagram"
                            target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} className="icon" />
                        </a>
                    </div>
                    {/* Display the Tit Balance underneath the social media icons */}
                    <div className="tit-balance">
                        {/* <p>{titBalance} TIT$</p> */}
                        <p>{Number(flowBalance) ? Number(flowBalance).toFixed(2) : '0.00'} $FLOW</p>

                    </div>
                </div>
                <div className="contact-info">  {/* Third column on the right */}
                   {/*  <p>Email: {currentUser?.email || 'N/A'}</p>   Show email */}
                    <p><strong>Flow Address:</strong> {userAddress || 'N/A'}</p>   {/* Show Flow address */}

                </div>
            </div>
        </footer>
    );
}
