export const getCourtierDetailsX1 = `
import CourtiersX from 0xfdfe39186c5e3b90
import NonFungibleToken from 0x1d7e57aa55817448 

// Define a struct to hold the NFT ID, name, type, and thumbnail
access(all) struct NFTDetails {
    access(all) let nftId: UInt64
    access(all) let name: String
    access(all) let description: String
    access(all) let thumbnail: String
    access(all) let traits: &[{String:String}]

    init(nftId: UInt64, name: String, description: String, thumbnail: String, traits: &[{String:String}]) {
        self.nftId = nftId
        self.name = name
        self.description = description
        self.thumbnail = thumbnail
        self.traits = traits
    }
}

access(all) fun main(accountAddress: Address): [NFTDetails] {
    // Get the account from the provided address
    let account = getAccount(accountAddress)

    // Prepare an array to hold the NFT details
    var nftDetails: [NFTDetails] = []

    // CAR CLUB DETAILS
    if let titPalaceCollectionRef = account.capabilities.borrow<&{CourtiersX.CollectionPublic}>(CourtiersX.CollectionPublicPath) {
        let titPalaceNFTIDs = titPalaceCollectionRef.getIDs()

        for titPalaceID in titPalaceNFTIDs {
            let titPalaceNFTRef = titPalaceCollectionRef.borrowCourtiersX(id: titPalaceID)
                ?? panic("Could not borrow the CourtiersX NFT with the given ID")

            nftDetails.append(NFTDetails(
                nftId: titPalaceNFTRef.id,
                name: titPalaceNFTRef.name,
                description: titPalaceNFTRef.description,
                thumbnail: titPalaceNFTRef.image,
                traits: titPalaceNFTRef.traits
            ))
        }

    }
    // Return the array of NFT details
    return nftDetails
}
    `
