import React, { useContext, useState, useEffect, useCallback } from 'react';
import "./parlor.css";
import * as fcl from '@blocto/fcl';
import { getPortraitDetails } from '../../cadence/scripts/getPortraitDetails'; // Fetches user's portraits
import { getTitBalance } from '../../cadence/scripts/getTitBalance'; // Fetches Tit balance
import { getPortraitAccounts } from '../../cadence/scripts/getPortraitAccounts'; // Fetches mappings of IDs to Flow addresses
import { UserContext } from '../../contexts/userContext';
import { images } from '../../assets/images';
import { getNextLevelXP, getCurrentLevel } from '../../config/levelsconfig'; // XP config
import { getModelDetails } from '../../cadence/scripts/getModelDetailsX1';
import { getCourtierDetails } from '../../cadence/scripts/getCourtierDetails';
import courtiersData from '../../cadence/data/courtiers.json'; 
import pagesData from '../../cadence/data/pages.json';
import  pageImages from '../../assets/pageImages';
import courtierImages from '../../assets/courtierImages';
import { ThreeDots } from "react-loader-spinner";


export default function Parlor() {
    const [portraits, setPortraits] = useState([]);  // Stores portraits owned by the user
    const [courtiers, setCortiers] = useState([]);
    const { flowAddress, loading } = useContext(UserContext);
    const [idToFlowMap, setIdToFlowMap] = useState({});  // Mapping of portrait IDs to Flow addresses
    const [balances, setBalances] = useState({});  // Mapping of Flow addresses to Tit balances
    const [isFetching, setIsFetching] = useState(true); // Track fetching status
    const [user, setUser] = useState({ loggedIn: null });
    const [userAddress, setUserAddress] = useState(null);
    const [userXP, setUserXP] = useState(0);  // Tracks user's total XP
    const [userLevel, setUserLevel] = useState(0);  // Tracks user's current level
    const [nextLevelXP, setNextLevelXP] = useState(0);  // Tracks XP needed for the next level


    // Track the entire process of fetching and subscribing
    useEffect(() => {
        fcl.currentUser.subscribe(currentUser => {
            console.log("User subscription triggered:", currentUser);
            setUser(currentUser);
            setUserAddress(currentUser.addr);
        });
    }, []);

    // const fetchIdToFlowMap = useCallback(async () => {
    //     console.log("Fetching Flow address mappings...");
    //     try {
    //         const usersResult = await fcl.query({
    //             cadence: getPortraitAccounts,  // Cadence script to fetch Flow address mappings
    //         });
    //         console.log("Mappings fetched:", usersResult);
    
    //         const mapping = {};
    //         for (const user of usersResult) {
    //             if (user.titID && user.flowAddress) {
    //                 mapping[user.titID] = user.flowAddress;  // Map each titID to its Flow address
    //             }
    //         }
    
    //         console.log("Mappings processed:", mapping);
    //         setIdToFlowMap(mapping);
    //         fetchBalances(mapping);
    //     } catch (error) {
    //         console.error('Error fetching ID to Flow address mappings:', error);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (!loading && flowAddress) {
    //         console.log("Fetching portraits for flow address:", flowAddress);
    //         fetchPortraits(flowAddress);  // Fetch user's portraits
    //         fetchIdToFlowMap();  // Fetch the ID to Flow address mapping
    //     }
    // }, [flowAddress, loading, fetchIdToFlowMap]);

    useEffect(() => {
        console.log("Address for script entry:", userAddress);
        if (userAddress) {
            fetchPortraits(userAddress);
        }
    }, [userAddress]);

    useEffect(() => {
        console.log('Portraits loaded:', portraits);
    }, [portraits]);

    useEffect(() => {
        console.log('Popup images data:', images.popupImages);
    }, []);

    const calculateUserLevelAndXP = (portraits) => {
        console.log("Calculating XP from portraits:", portraits);
    
        // Group portraits by name and count occurrences
        const xpMap = portraits.reduce((acc, portrait) => {
            const name = portrait.nftData.name;
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});
    
        console.log("XP Map (name counts):", xpMap);
    
        // Calculate total XP
        const totalXP = Object.values(xpMap).reduce((sum, count) => sum + count, 0);
    
        let currentLevel = 0;
        let cumulativeXP = 0; // Total XP required to reach the current level
        let nextLevelXP = getNextLevelXP(currentLevel);
    
        // Determine the level and calculate cumulative XP
        while (totalXP >= cumulativeXP + nextLevelXP) {
            currentLevel += 1;
            cumulativeXP += nextLevelXP; // Add the XP needed for the current level
            nextLevelXP = getNextLevelXP(currentLevel); // Get XP for the next level
        }
    
        const xpTowardNextLevel = totalXP - cumulativeXP; // XP progress in the current level
    
        console.log(`Total XP: ${totalXP}, Current Level: ${currentLevel}, XP for Next Level: ${nextLevelXP}, XP toward Next Level: ${xpTowardNextLevel}`);
    
        // Update state
        setUserXP(xpTowardNextLevel); // XP within the current level
        setUserLevel(currentLevel);
        setNextLevelXP(nextLevelXP);
    };
    
    

    const fetchPortraits = async (address) => {
        setIsFetching(true);
        try {
            const [pagesResult, courtiersResult] = await Promise.all([
                fcl.query({
                    cadence: getModelDetails,
                    args: (arg, t) => [arg(address, t.Address)],
                }),
                fcl.query({
                    cadence: getCourtierDetails,
                    args: (arg, t) => [arg(address, t.Address)],
                }),
            ]);

            const allPortraits = [...pagesResult, ...courtiersResult].map(portrait => ({
                ...portrait,
                enrichedTraits: enrichTraits(portrait.nftData.name, portrait.nftData.traits || []),
            }));

            console.log("All Portraits: ", allPortraits);

            setPortraits(allPortraits);
            calculateUserLevelAndXP(allPortraits);
        } catch (error) {
            console.error('Error fetching portraits:', error);
        } finally {
            setIsFetching(false);
        }
    };

    
    
    const enrichTraits = (name, traits) => {
        console.log("Enriching traits for:", name);
        
        // Default to an empty array if traits is not already an array
        let enrichedTraits = Array.isArray(traits) ? traits : [];
    
        // Check if the name is in courtiersData (case-insensitive matching)
        const courtier = courtiersData.Courtiers.find(courtier => courtier.Name.toLowerCase() === name.toLowerCase());
        if (courtier) {
            console.log(`Courtier match found for ${name}:`, courtier);
            enrichedTraits = courtier.traits;
            console.log(`Traits from courtiers.json for ${name}:`, enrichedTraits);
        } else {
            console.log(`No courtier match found for ${name}. Checking pages.json...`);
            // If not a courtier, check pages.json
            const page = pagesData.Pages.find(page => page.alt.split(' - ')[0].trim().toLowerCase() === name.toLowerCase());
            if (page) {
                console.log(`Page match found for ${name}:`, page);
                enrichedTraits = page.traits;
                console.log(`Traits from pages.json for ${name}:`, enrichedTraits);
            } else {
                console.warn(`No match found in courtiers.json or pages.json for ${name}.`);
            }
        }
    
        // Return the final enriched traits
        console.log(`Final enriched traits for ${name}:`, enrichedTraits);
        return enrichedTraits;
    };
    
    
    // Step 3: Fetch Tit balance for each Flow address in the mapping, with logging
    // const fetchBalances = async (mapping) => {
    //     console.log("Fetching balances for mapping:", mapping);
    //     const balances = {};
    //     for (const nftId in mapping) {
    //         const flowAddress = mapping[nftId];  // Get the Flow address from the mapping
    //         if (flowAddress) {
    //             const balance = await fcl.query({
    //                 cadence: getTitBalance,
    //                 args: (arg, t) => [arg(flowAddress, t.Address)]
    //             });
    //             balances[flowAddress] = balance;  // Store balance against Flow address
    //         }
    //     }
    //     console.log("Balances fetched:", balances);
    //     setBalances(balances);  // Save the balances
    // };

    // // Match portrait name with the first part of the alt in popupImages
    // const getMatchingImageData = (portraitName) => {
    //     const matchingImage = images.popupImages.find(image => {
    //         const [imageName] = image.alt.split(' - ');  // Take only the part before ' - '
    //         return imageName === portraitName;
    //     });
    //     console.log(`Matching image data for ${portraitName}:`, matchingImage);
    //     return matchingImage || null;
    // };


    const renderImage = (thumbnail, type) => {
        if (!thumbnail || thumbnail === "N/A") {
            return "";  // Return a default image or empty if no valid thumbnail
        }

        console.log('Thumbnail: ', thumbnail, "Type: ", type);
    
        // Pick the correct image collection
        const imgPath = type === 7 ? pageImages[thumbnail] : courtierImages[thumbnail];
    
        if (!imgPath) {
            console.error(`No image found for thumbnail: ${thumbnail}`);
            return "";  // Return a default image if no match found
        }
    
        return imgPath;
    };
    
    
    
    const renderTrait = (traitKey, trait) => {
        // console.log(`Rendering trait with key ${traitKey}:`, trait);
        
        if (!trait || typeof trait !== 'object') {
            console.warn('Invalid trait:', trait);
            return null;
        }
    
        const symbol = trait.modifier && trait.modifier.includes('multiply') ? 'x' : 
                       trait.modifier && trait.modifier.includes('increase') ? '+' : '';
        const valueWithSymbol = symbol ? `${symbol} ${trait.value}` : trait.value;
    
        return (
            <div key={traitKey} className="trait">
                <div className="trait-row-1">
                    <div className="trait-category-name">
                        {trait.category}: {trait.name}
                    </div>
                    <div className="trait-value">
                        {valueWithSymbol}
                    </div>
                </div>
                <div className="trait-row-2">
                    <div className="trait-description">
                        <small>{trait.description}</small>
                    </div>
                </div>
            </div>
        );
    };
    
    
        // Step 5: Render stars based on the exponential growth formula
        const renderStars = (level) => {
            let stars = 0;
            let requiredCount = 5;
        
            // Exponential growth to calculate the number of stars
            while (level >= requiredCount && stars < 5) {
                stars += 1;
                requiredCount = 5 * (2 ** (stars - 1));  // Exponentially increase the number of portraits needed for next star
            }
        
            // Render stars based on the number of stars calculated
            return Array.from({ length: 5 }).map((_, index) => (
                <svg
                    key={index}
                    className={`star ${index < stars ? 'filled' : ''}`}  // Fill stars based on count
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <polygon points="12,2 15,9 22,9 17,14 18,21 12,17 6,21 7,14 2,9 9,9" />
                </svg>
            ));
        };
        
        // Step 4: Group portraits by name and count occurrences
        const groupPortraitsByName = (portraits) => {
            const portraitCount = {};
            portraits.forEach((portrait) => {
                const name = portrait.nftData.name; // Access portrait name
                portraitCount[name] = (portraitCount[name] || 0) + 1; // Increment count
            });
            return portraitCount;
        };
        

    const normalizeTraits = (traits) => {
        console.log("Normalizing traits:", traits);
        
        // Ensure traits is an array
        if (!Array.isArray(traits)) {
            console.warn('Traits is not an array:', traits);
            return [];
        }
    
        const selectedTraits = traits
            .map((trait, index) => {
                // console.log(`Rendering trait ${index}:`, trait);
                return renderTrait(index, trait);
            })  // Map the traits array to render them
            .filter(Boolean);  // Remove any null or undefined values
    
        console.log("Selected traits for randomization:", selectedTraits);
    
        // Randomly select two traits
        const randomTraits = selectedTraits.sort(() => 0.5 - Math.random()).slice(0, 2);
        console.log("Randomly selected traits:", randomTraits);
    
        return randomTraits;
    };
    
    
    const getCourtierName = (name) => {
        // Split the name to remove the title
        const nameParts = name.split(" ");
        if (nameParts.length >= 2) {
            const firstName = nameParts[1];  // Get the first name (second word in the string)
            const lastName = nameParts.slice(2).join(" ");  // Join the remaining parts as the last name
            return `${firstName.charAt(0)}. ${lastName}`;  // Return formatted as "F. LastName"
        }
        return name;  // Fallback if the name isn't in the expected format
    };
    

    const renderPortraitTile = (portrait, count, index) => {
        if (!portrait) return null;
    
        const name = getCourtierName(portrait.nftData.name);
        const imageSrc = renderImage(portrait.nftData.image, portrait.nftData.traits.length);
    
        const cardXP = count; // Total XP for the card
        let cardLevel = 0;
        let cumulativeXP = 0; // Total XP required to reach the current level
        let cardNextLevelXP = getNextLevelXP(cardLevel);
    
        // Determine the level and calculate cumulative XP
        while (cardXP >= cumulativeXP + cardNextLevelXP) {
            cardLevel += 1;
            cumulativeXP += cardNextLevelXP; // Add the XP needed for the current level
            cardNextLevelXP = getNextLevelXP(cardLevel); // Get XP for the next level
        }
    
        const xpTowardNextLevel = cardXP - cumulativeXP; // XP progress in the current level
        const xpPercentage = Math.min((xpTowardNextLevel / cardNextLevelXP) * 100, 100); // Fill percentage for XP bar
    
        console.log(`Rendering "${name}" with XP: ${cardXP}, Level: ${cardLevel}, XP toward Next Level: ${xpTowardNextLevel}, XP for Next Level: ${cardNextLevelXP}`);
    
        // Calculate HP by summing up trait values
        const hpValue = portrait.enrichedTraits.reduce((sum, trait) => {
            const value = parseFloat(trait.value) || 0;
            return sum + value;
        }, 0);
    
        const randomTraits = normalizeTraits(portrait.enrichedTraits);
    
        return (
            <div key={index} className="portrait-tile">
                <div className="name-hp-container">
                    <h3>{name}</h3>
                    <div className="hp-container">
                        <span className="hp-value">{hpValue}</span>
                        <span className="hp-label">HP</span>
                    </div>
                </div>
    
                {imageSrc ? (
                    <div className="image-banner">
                        <img src={imageSrc} alt={portrait.nftData.name} />
                        <div className="description-banner">{portrait.nftData.description}</div>
                    </div>
                ) : (
                    <p>Image not found</p>
                )}
                <div className="portrait-details">
                    {randomTraits.length > 0 ? randomTraits : <span>No traits available</span>}
                </div>
                <div className="xp-bar-container">
                    <div className="xp-bar">
                        <div className="xp-bar-fill" style={{ width: `${xpPercentage}%` }} />
                    </div>
                    <div className="xp-info">
                        <span className="level-info">Level {cardLevel}</span>
                        <span className="xp-info-right">{xpTowardNextLevel}/{cardNextLevelXP} XP</span>
                    </div>
                </div>
            </div>
        );
    };
    


    const pagesPortraits = portraits.filter(p => {
        return pagesData.Pages.some(page => page.alt.split(' - ')[0].trim().toLowerCase() === p.nftData.name.toLowerCase());
    });
    
    const courtiersPortraits = portraits.filter(p => {
        return courtiersData.Courtiers.some(courtier => courtier.Name.toLowerCase() === p.nftData.name.toLowerCase());
    });
    const renderXPBar = () => {
        const xpForNextLevel = nextLevelXP || userXP;  // Handle edge case when user reaches max level
        const xpPercentage = (userXP / xpForNextLevel) * 100;
    
        return (
            <div className="xp-bar-container">
                <div className="xp-bar">
                    <div className="xp-bar-fill" style={{ width: `${xpPercentage}%` }} />
                </div>
                <div className="xp-info">
                    <span className="level-info">Level {userLevel}</span>
                    <span className="xp-info-right">{userXP}/{xpForNextLevel} XP</span>
                </div>
            </div>
        );
    };
    
    

    return (
        <div>
            <div className="top-container">
                <h1>Parlor</h1>
            </div>

            {isFetching ? (
                <div className="loading-container">
                    <ThreeDots height="80" width="80" color="#007d7e" ariaLabel="loading-indicator"/>
                </div>
            ) : portraits.length === 0 ? (
                <p>No portraits found.</p> 
            ) : (

                <>
                    {/* Pages Section */}
                    <div className="courtiers-section">
                        <h2>Pages</h2>
                        <div className="portrait-grid">
                            {Object.entries(groupPortraitsByName(pagesPortraits)).map(([name, count], index) => {
                                const portrait = portraits.find((p) => p.nftData.name === name);
                                return renderPortraitTile(portrait, count, index);
                            })}
                        </div>
                    </div>

                    <div className="courtiers-section-real">
                        <h2>Courtiers</h2>
                        <div className="portrait-grid">
                            {Object.entries(groupPortraitsByName(courtiersPortraits)).map(([name, count], index) => {
                                const portrait = portraits.find((p) => p.nftData.name === name);
                                return renderPortraitTile(portrait, count, index);
                            })}
                        </div>
                    </div>*
                </>
            )}
        </div>
    );
    
}
