// Import all images
import img1 from './popup/1.png';
import img2 from './popup/2.png';
import img3 from './popup/3.png';
import img4 from './popup/4.png';
import img5 from './popup/5.png';
import img6 from './popup/6.png';
import img7 from './popup/7.png';
import img8 from './popup/8.png';
import img9 from './popup/9.png';
import img10 from './popup/10.png';
import img11 from './popup/11.png';
import img12 from './popup/12.png';
import img13 from './popup/13.png';
import img14 from './popup/14.png';
import img15 from './popup/15.png';
import img16 from './popup/16.png';
import img17 from './popup/17.png';
import img18 from './popup/18.png';
import img19 from './popup/19.png';
import img20 from './popup/20.png';
import img21 from './popup/21.png';
import img22 from './popup/22.png';
import img23 from './popup/23.png';
import img24 from './popup/24.png';
import img25 from './popup/25.png';
import img26 from './popup/26.png';
import img27 from './popup/27.png';
import img28 from './popup/28.png';
import img29 from './popup/29.png';
import img30 from './popup/30.png';
import img31 from './popup/31.png';
import img32 from './popup/32.png';
import img33 from './popup/33.png';
import img34 from './popup/34.png';
import img35 from './popup/35.png';
import img36 from './popup/36.png';
import img37 from './popup/37.png';
import img38 from './popup/38.png';
import img40 from './popup/40.png';
import img41 from './popup/41.png';
import img42 from './popup/42.png';
import img43 from './popup/43.png';
import img44 from './popup/44.png';
import img45 from './popup/45.png';
import img46 from './popup/46.png';
import img47 from './popup/47.png';
import img48 from './popup/48.png';
import img49 from './popup/49.png';
import img50 from './popup/50.png';

const pageImages = {
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
    img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
    img21, img22, img23, img24, img25, img26, img27, img28, img29, img30,
    img31, img32, img33, img34, img35, img36, img37, img38, img40, img41,
    img42, img43, img44, img45, img46, img47, img48, img49, img50
};

export default pageImages;
