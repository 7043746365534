export const purchasePageListing = `
import FungibleToken from 0xf233dcee88fe0abe
import NonFungibleToken from 0x1d7e57aa55817448
import TitPalaceStorefrontXxX from 0xfdfe39186c5e3b90
import PagesX from 0xfdfe39186c5e3b90
import CourtiersX from 0xfdfe39186c5e3b90
import TitToken from 0x66b60643244a7738

transaction(storefrontAddress: Address, listingResourceID: UInt64, expectedPrice: UFix64) {

    // Local references
    let paymentVault: @{FungibleToken.Vault}
    let nftCollections: {Type: &{NonFungibleToken.CollectionPublic}}
    let storefront: &TitPalaceStorefrontXxX.Storefront // Borrowing the actual Storefront resource
    let listingRef: &{TitPalaceStorefrontXxX.ListingPublic}
    let tokenVault: &{FungibleToken.Receiver}

    prepare(acct: auth(Storage, Capabilities) &Account) {
        self.nftCollections = {}

        // Borrow the storefront reference from the specified address
        self.storefront = getAccount(storefrontAddress)
            .capabilities.borrow<&TitPalaceStorefrontXxX.Storefront>(TitPalaceStorefrontXxX.StorefrontPublicPath)
            ?? panic("Could not borrow the storefront resource.")

        // Borrow a reference to the specific listing
        self.listingRef = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No listing found with the given ID.")

        // Get the sale price from the listing
        let salePrice = self.listingRef.getDetails().salePrice

        // Ensure the expected price matches the listing price
        assert(expectedPrice == salePrice, message: "The sale price does not match the expected price.")

        // Withdraw the sale price from the buyer's FungibleToken vault
        let fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow the buyer's Flow Token vault reference.")
        self.paymentVault <- fungibleTokenVaultRef.withdraw(amount: salePrice)

        // Ensure the buyer's NFT collection exists, or set it up
        if acct.storage.borrow<&PagesX.Collection>(from: PagesX.CollectionStoragePath) == nil {
            let collection <- PagesX.createEmptyCollection(nftType: Type<@PagesX.NFT>())
            acct.storage.save(<-collection, to: PagesX.CollectionStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(PagesX.CollectionStoragePath),
                at: PagesX.CollectionPublicPath
            )
        }

        // Borrow the buyer's NFT collection
        self.nftCollections[Type<@PagesX.NFT>()] = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: PagesX.CollectionStoragePath)
            ?? panic("Could not borrow the buyer's NFT collection.")

                // Ensure the buyer's NFT collection exists, or set it up
        if acct.storage.borrow<&CourtiersX.Collection>(from: CourtiersX.CollectionStoragePath) == nil {
            let collection <- CourtiersX.createEmptyCollection(nftType: Type<@CourtiersX.NFT>())
            acct.storage.save(<-collection, to: CourtiersX.CollectionStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(CourtiersX.CollectionStoragePath),
                at: CourtiersX.CollectionPublicPath
            )
        }

        // Borrow the buyer's NFT collection
        self.nftCollections[Type<@CourtiersX.NFT>()] = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: CourtiersX.CollectionStoragePath)
            ?? panic("Could not borrow the buyer's NFT collection.")

        // Ensure the buyer's TitToken vault exists or set it up
        if acct.storage.borrow<&TitToken.Vault>(from: TitToken.VaultStoragePath) == nil {
            let vroomVault <- TitToken.createEmptyVault(vaultType: Type<@TitToken.Vault>())
            acct.storage.save(<-vroomVault, to: TitToken.VaultStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(TitToken.VaultStoragePath),
                at: TitToken.VaultReceiverPath
            )
        }

        // Borrow the buyer's TitToken vault
        self.tokenVault = acct.storage.borrow<&{FungibleToken.Receiver}>(from: TitToken.VaultStoragePath)
            ?? panic("Could not borrow the buyer's TitToken vault.")
    }

    execute {
        // Call the purchase method on the listing
        self.listingRef.purchase(
            payment: <-self.paymentVault,
            nftCollections: self.nftCollections,
            ftReceiver: self.tokenVault
        )

        // Remove the listing after successful purchase
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}
`